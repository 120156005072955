.blog-empty-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #000000;
}
.blog-empty-container1 {
  flex: 0 0 auto;
  width: auto;
  height: 1387px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.blog-empty-container2 {
  flex: 0 0 auto;
  width: 1185px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 1339px;
  display: flex;
  padding: 0px;
  position: relative;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-theme-gray);
}
@media(max-width: 1200px) {
  .blog-empty-container1 {
    width: 100%;
    height: 100%;
  }
  .blog-empty-container2 {
    flex: 100;
    width: var(--dl-size-size-xlarge);
    height: 1339px;
  }
}
