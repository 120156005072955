.blog-dir-container {
  width: 260px;
  height: 1387px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-theme-neutral-dark);
}
.blog-dir-container1 {
  width: 100%;
  height: 41px;
  display: flex;
  position: relative;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  justify-content: flex-end;
}
.blog-dir-button {
  top: 0px;
  fill: var(--dl-color-theme-neutral-light);
  left: 0px;
  color: var(--dl-color-theme-neutral-light);
  right: 0px;
  width: var(--dl-size-size-medium);
  bottom: 0px;
  height: 38px;
  padding: 0px;
  position: absolute;
  margin-top: auto;
  transition: 0.3s;
  font-family: "Courier New";
  margin-left: 25px;
  margin-right: auto;
  margin-bottom: auto;
  background-color: rgb(37, 37, 37);
}
.blog-dir-button:hover {
  background-color: var(--dl-color-theme-primary2);
}
.blog-dir-button1 {
  top: 0px;
  fill: var(--dl-color-theme-neutral-light);
  left: 0px;
  color: var(--dl-color-theme-neutral-light);
  right: 0px;
  width: var(--dl-size-size-medium);
  bottom: 0px;
  height: 38px;
  position: absolute;
  margin-top: auto;
  transition: 0.3s;
  margin-left: auto;
  margin-right: 25px;
  margin-bottom: auto;
  background-color: #252525;
}
.blog-dir-button1:hover {
  background-color: var(--dl-color-theme-primary2);
}
.blog-dir-text {
  font-family: "Courier New";
}
.blog-dir-ul {
  width: 0px;
  height: 93px;
  margin-top: 0px;
  margin-left: 0px;
  padding-top: var(--dl-space-space-twounits);
  margin-right: 0px;
  padding-left: 0px;
  margin-bottom: 0px;
  padding-right: 5px;
  padding-bottom: var(--dl-space-space-twounits);
}
.blog-dir-li {
  font-size: 22px;
  margin-left: 0px;
  padding-top: 0px;
  margin-right: 0px;
}
.blog-dir-text03 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  text-align: left;
  font-family: "Courier New";
}
.blog-dir-li1 {
  font-size: 22px;
  margin-left: 0px;
  padding-top: 0px;
  margin-right: 0px;
}
.blog-dir-text06 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-family: "Courier New";
}
.blog-dir-li2 {
  font-size: 22px;
  margin-left: 0px;
  padding-top: 0px;
  margin-right: 0px;
}
.blog-dir-text09 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-family: "Courier New";
}
.blog-dir-li3 {
  font-size: 22px;
  margin-left: 0px;
  padding-top: 0px;
  margin-right: 0px;
}
.blog-dir-text10 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-family: "Courier New";
}
.blog-dir-li4 {
  font-size: 22px;
  margin-left: 0px;
  padding-top: 0px;
  margin-right: 0px;
}
.blog-dir-text13 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-family: "Courier New";
}
.blog-dir-ul1 {
  margin-top: var(--dl-space-space-fourunits);
  padding-top: var(--dl-space-space-twounits);
}
.blog-dir-post-2 {
  font-size: 15px;
}
.blog-dir-text16 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-family: "Courier New";
}
.blog-dir-text19 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-family: "Courier New";
  margin-left: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
}
.blog-dir-post-1-example {
  font-size: 15px;
}
.blog-dir-text22 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-family: "Courier New";
}
.blog-dir-text25 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-family: "Courier New";
  margin-left: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
}



@media(max-width: 1200px) {
  .blog-dir-container {
    width: 300px;
  }
}
