.blog-home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #000000;
}
.blog-home-container1 {
  flex: 0 0 auto;
  width: auto;
  height: 1387px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.blog-home-container2 {
  flex: 0 0 auto;
  width: 1185px;
  height: 1339px;
  display: flex;
  padding: 0px;
  position: relative;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-theme-gray);
}
.blog-home-text {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-size: 1em;
  align-self: flex-start;
  font-style: normal;
  font-family: "Courier New";
  font-weight: 400;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: 0px;
}
@media(max-width: 1200px) {
  .blog-home-container1 {
    width: 100%;
    height: 100%;
  }
  .blog-home-container2 {
    flex: 100;
    width: var(--dl-size-size-xlarge);
    height: 1339px;
  }
  .blog-home-text {
    align-self: flex-start;
    padding-left: var(--dl-space-space-sixunits);
    padding-right: 0px;
  }
}
