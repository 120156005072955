.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-theme-neutral-dark);
}
.home-ul {
  top: 0px;
  left: 0px;
  right: 0px;
  width: var(--dl-size-size-xxlarge);
  bottom: 0px;
  height: 232px;
  margin: auto;
  z-index: 0;
  position: absolute;
  border-radius: var(--dl-radius-radius-imageradius);
  background-color: #000000;
}
.home-li {
  position: relative;
  margin-top: var(--dl-space-space-oneandhalfunits);
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.home-text {
  left: 0px;
  color: rgb(255, 255, 255);
  right: 24px;
  bottom: 10px;
  margin: auto;
  position: absolute;
  text-align: center;
  font-family: "Courier New";
}
.home-li1 {
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.home-text01 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-family: "Courier New";
}
.home-link {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  transition: 0.3s;
  font-family: "Courier New";
}
.home-link:hover {
  font-style: normal;
  font-weight: 700;
}
.home-li2 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  width: 100%;
  position: relative;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.home-text04 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-family: "Courier New";
}
.home-link1 {
  top: 18px;
  fill: var(--dl-color-theme-neutral-light);
  left: 106px;
  color: var(--dl-color-theme-neutral-light);
  height: var(--dl-size-size-xsmall);
  position: absolute;
  transition: 0.3s;
  font-family: "Courier New";
}
.home-link1:hover {
  font-style: normal;
  font-weight: 700;
}
.home-li3 {
  position: relative;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.home-container1 {
  flex: 0 0 auto;
  width: 289px;
  height: 102px;
  display: flex;
  position: relative;
  margin-top: -40px;
  align-items: flex-start;
  margin-left: -24px;
}
.home-link2 {
  top: 32px;
  fill: var(--dl-color-theme-neutral-light);
  left: 20px;
  color: var(--dl-color-theme-neutral-light);
  width: 110px;
  height: 38px;
  padding: 0.5rem;
  z-index: 10;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  text-decoration: none;
  background-color: var(--dl-color-theme-neutral-dark);
}
.home-link2:hover {
  background-color: #383535;
}
.home-text10 {
  top: 9px;
  left: 12px;
  height: auto;
  position: absolute;
  font-family: "Courier New";
}
.home-link3 {
  top: 32px;
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  right: 20px;
  width: 110px;
  height: 38px;
  z-index: 10;
  position: absolute;
  align-self: center;
  text-align: center;
  transition: 0.3s;
  text-decoration: none;
  background-color: var(--dl-color-theme-neutral-dark);
}
.home-link3:hover {
  background-color: #272525;
}
.home-text13 {
  top: 9px;
  right: 35px;
  position: absolute;
  text-align: center;
  font-family: "Courier New";
}
